import * as Turbo from '@hotwired/turbo'
Turbo.session.drive = false // Disable Turbo by default
import '../controllers' // Stimulus controller

import React from 'react'
import { createRoot } from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'

import client from '../functions/client'
import legacy from '../functions/legacy'
import parseProps from '../functions/parseProps'
import animateStores from '../functions/animateStores'
import initImageGallery from '../functions/imageGallery'
import Calculator from '../containers/Calculator'
import ColorInput from '../components/ColorInput'

import '@client-side-validations/client-side-validations/src'
import '@client-side-validations/simple-form/src/index.bootstrap4'

import 'cocoon-js-vanilla'
import 'animate.css'

document.addEventListener('DOMContentLoaded', () => {
  // All our legacy with 👹 globals
  legacy()

  // Initialize image gallery (photoswipe)
  initImageGallery()

  // Animations on the front page
  if (
    document.body.classList.contains('index') &&
    document.body.id === 'welcome'
  ) {
    animateStores()
  }

  // Color pickers for Tags
  const targetColorPickerContainer = document.getElementById(
    'anchor-pack-color-picker'
  )
  if (targetColorPickerContainer) {
    const targetColorPickerRoot = createRoot(targetColorPickerContainer)
    const props = parseProps(targetColorPickerContainer)

    targetColorPickerRoot.render(<ColorInput {...props} />)
  }

  // Shipping Calculator
  const targetCalculatorContainer = document.getElementById(
    'anchor-pack-calculator'
  )
  if (targetCalculatorContainer) {
    const targetCalculatorRoot = createRoot(targetCalculatorContainer)

    targetCalculatorRoot.render(
      <ApolloProvider client={client}>
        <Calculator />
      </ApolloProvider>
    )
  }
})
