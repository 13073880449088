import React from 'react'

export default function Card({
  intent = '',
  flush = false,
  header = null,
  body = null,
  footer = null,
  style = {},
}) {
  const classNames = []
  if (intent !== '') {
    classNames.push(`bg-${intent}`)

    if (intent === 'warning') {
      classNames.push('text-warning')
    } else if (intent === 'info' || intent === 'light') {
      classNames.push('text-dark')
    } else {
      classNames.push('text-white')
    }
  }

  return (
    <div
      className={`card ${classNames.join(' ')}`}
      style={{ overflow: 'initial', ...style }}
    >
      {header && <div className='card-header'>{header}</div>}
      {body && <div className={flush ? '' : 'card-body'}>{body}</div>}
      {footer && <div className='card-footer text-right'>{footer}</div>}
    </div>
  )
}
