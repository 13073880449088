import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    countryPath: String,
  }

  changeCountry() {
    get(this.countryPathValue, {
      query: { country: event.target.value },
      contentType: 'application/json',
      responseKind: 'turbo-stream',
    })
  }
}
