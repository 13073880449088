// Are we on the /after_signup/add_address page?
if ($('#after_signup').length) {
  const loadCities = (country) => {
    $('.js-city-select select')
      .empty()
      .append($('<option>').text('').attr('value', ''))
    $.ajax('/cities', {
      type: 'GET',
      dataType: 'json',
      data: { country },
      error: (jqXHR, textStatus) => {
        console.error('AJAX Error: ' + textStatus)
      },
      success: (data) => {
        if (data.length > 0) {
          $.each(data, (i, value) => {
            $('.js-city-select select').append(
              $('<option>').text(value[0]).attr('value', value[1])
            )
          })
          $('.js-city-select').removeClass('hide')
          $('.js-city-input').addClass('hide')
        } else {
          $('.js-city-input').removeClass('hide')
          $('.js-city-select').addClass('hide')
        }
      },
    })
  }

  $('select#user_destination_addresses_attributes_0_country').on(
    'change',
    (e) => {
      const countryCode = e.target.value
      loadCities(countryCode)
      if (
        countryCode === 'RU' ||
        countryCode === 'BY' ||
        countryCode === 'KZ'
      ) {
        $('.js-per-country-ca-true').addClass('hide')
        $('.js-per-country-ca-us-false').removeClass('hide')
        $('.js-per-country-ru-false').addClass('hide')
        $('.js-per-country-ru-true').removeClass('hide')
        $('.js-per-country-us-true').addClass('hide')
        $('.js-per-country-kr-true').addClass('hide')
      } else if (countryCode === 'US') {
        $('.js-per-country-ca-true').addClass('hide')
        $('.js-per-country-ca-us-false').addClass('hide')
        $('.js-per-country-ru-false').removeClass('hide')
        $('.js-per-country-ru-true').addClass('hide')
        $('.js-per-country-us-true').removeClass('hide')
        $('.js-per-country-kr-true').addClass('hide')
      } else if (countryCode === 'CA') {
        $('.js-per-country-ca-true').removeClass('hide')
        $('.js-per-country-ca-us-false').addClass('hide')
        $('.js-per-country-ru-false').removeClass('hide')
        $('.js-per-country-ru-true').addClass('hide')
        $('.js-per-country-us-true').addClass('hide')
        $('.js-per-country-kr-true').addClass('hide')
      } else if (countryCode === 'KR') {
        $('.js-per-country-ca-true').addClass('hide')
        $('.js-per-country-ca-us-false').removeClass('hide')
        $('.js-per-country-ru-false').removeClass('hide')
        $('.js-per-country-ru-true').addClass('hide')
        $('.js-per-country-us-true').addClass('hide')
        $('.js-per-country-kr-true').removeClass('hide')
      } else {
        $('.js-per-country-ca-true').addClass('hide')
        $('.js-per-country-ca-us-false').removeClass('hide')
        $('.js-per-country-ru-false').removeClass('hide')
        $('.js-per-country-ru-true').addClass('hide')
        $('.js-per-country-us-true').addClass('hide')
        $('.js-per-country-kr-true').addClass('hide')
      }
    }
  )
  $('select#us_state').on('change', (e) => {
    const stateCode = e.target.value
    $('input#user_destination_addresses_attributes_0_state').val(stateCode)
  })
  $('select#ca_province').on('change', (e) => {
    const provinceCode = e.target.value
    $('input#user_destination_addresses_attributes_0_state').val(provinceCode)
  })
}
