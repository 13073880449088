let loadCities = (country) => {
  $('#destination_address_city_id').empty()
  $.ajax('/cities', {
    type: 'GET',
    dataType: 'json',
    data: {
      country,
    },
    error(jqXHR, textStatus) {
      console.error(`AJAX Error: ${textStatus}`)
    },
    success(data) {
      console.log(`Cities loaded for country: ${country}`)
      $('#destination_address_city_id').empty()
      $('#destination_address_city_id').append(
        $('<option>').text('').attr('value', '')
      )
      $.each(data, (i, value) =>
        $('#destination_address_city_id').append(
          $('<option>').text(value[0]).attr('value', value[1])
        )
      )
      if (data.length > 0) {
        $('.form-group.destination_address_city_id').removeClass('hide')
        $('.form-group.destination_address_city').addClass('hide')
      } else {
        $('.form-group.destination_address_city').removeClass('hide')
        $('.form-group.destination_address_city_id').addClass('hide')
      }
    },
  })
}

$('table.address.destination td').each(function () {
  if ($(this).text().length > 20) {
    $(this).popover({
      title: $(this).siblings('th').text(),
      content: $(this).text(),
      placement: 'bottom',
    })
  }
})

$('a.phone').popover({
  template:
    '<div class="popover popover-item"><div class="arrow"></div><div class="popover-inner"><div class="popover-content popover-plain"><p></p></div></div></div>',
})

$('a.promotion_balance').popover()
$('.onchange_show_fields').change(function () {
  loadCities($(this).val())
  if ($(this).val() === 'US') {
    $('#destination_address_state').addClass('hide')
    $('#us_state').removeClass('hide')
    $('#canadian_province').addClass('hide')
  } else if ($(this).val() === 'CA') {
    $('#destination_address_state').addClass('hide')
    $('#us_state').addClass('hide')
    $('#canadian_province').removeClass('hide')
  } else {
    $('#destination_address_state').removeClass('hide')
    $('#us_state').addClass('hide')
    $('#canadian_province').addClass('hide')
  }
})

$('#us_state').change(function () {
  let state
  state = $(this).val()
  $('#destination_address_state').val(state)
})

$('#canadian_province').change(function () {
  let state
  state = $(this).val()
  $('#destination_address_state').val(state)
})
