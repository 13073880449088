// select2 searchable select inputs
function select2loadCustom() {
  // select2 Search Dropdown with Tags
  function formatStateTags({ id, text, element }) {
    if (!id) {
      return text
    }
    const $state = $(
      `<span><span class="material-symbols-outlined item-tag" style="color:${element.dataset.color};vertical-align:text-top;">sell</span> ${text}</span>`
    )
    return $state
  }
  $('.select2-tags').each(function () {
    $(this).select2({
      theme: 'bootstrap4',
      width: '100%',
      allowClear: true,
      templateResult: formatStateTags,
      templateSelection: formatStateTags,
      placeholder: $(this).attr('placeholder') || '',
    })
  })

  // select2 Search Dropdown with Flags
  const countryMarkers = {
    A: '1F1E6',
    B: '1F1E7',
    C: '1F1E8',
    D: '1F1E9',
    E: '1F1EA',
    F: '1F1EB',
    G: '1F1EC',
    H: '1F1ED',
    I: '1F1EE',
    J: '1F1EF',
    K: '1F1F0',
    L: '1F1F1',
    M: '1F1F2',
    N: '1F1F3',
    O: '1F1F4',
    P: '1F1F5',
    Q: '1F1F6',
    R: '1F1F7',
    S: '1F1F8',
    T: '1F1F9',
    U: '1F1FA',
    V: '1F1FB',
    W: '1F1FC',
    X: '1F1FD',
    Y: '1F1FE',
    Z: '1F1FF',
  }
  function formatStateFlags({ id, text, element }) {
    if (!id) {
      return text
    }
    const $state = $(
      `<span>&#x${countryMarkers[element.value[0]]};&#x${
        countryMarkers[element.value[1]]
      }; ${text}</span>`
    )
    return $state
  }
  $('.select2-flags').each(function () {
    $(this).select2({
      theme: 'bootstrap4',
      width: '100%',
      templateResult: formatStateFlags,
      templateSelection: formatStateFlags,
      placeholder: $(this).attr('placeholder') || '',
    })
  })
}

// Tooltips and Popover opt-in for Bootstrap
$('body').tooltip({
  selector: '[data-toggle="tooltip"]',
})
$('body').popover({
  selector: '[data-toggle="popover"]',
  trigger: 'focus', // Dismiss popovers on the user’s next click
  html: true,
})

// Datepicker
$('.datepicker_field').datepicker({
  format: 'yyyy-mm-dd',
  autoclose: true,
})

// select2 Search Dropdown without anything
$('.select2').each(function () {
  $(this).select2({
    theme: 'bootstrap4',
    width: '100%',
    allowClear: true,
    placeholder: $(this).attr('placeholder') || '',
  })
})

window.select2loadCustom = select2loadCustom

// Custom select2
select2loadCustom()
